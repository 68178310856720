(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("@audi/audi-ui-react-v2"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "styled-components", "@audi/audi-ui-react-v2"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("styled-components"), require("@audi/audi-ui-react-v2")) : factory(root["react"], root["styled-components"], root["@audi/audi-ui-react-v2"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__608__, __WEBPACK_EXTERNAL_MODULE__761__) {
return 