import { EditorialColumnsProps, EditorialColumnsFACE, NumbersItem, TextItem } from '../../types';
import { groupComponents } from './utils';

export const transformCmsDataToProps = (
  content: EditorialColumnsFACE,
): EditorialColumnsProps | null => {
  const textModuleContent = content.moduleContent.type as 'text';
  const numberModuleContent = content.moduleContent.type as 'numbers';
  let props: EditorialColumnsProps;

  const textModeComponentsGroup = groupComponents({
    components: content.moduleContent?.components,
    type: textModuleContent,
  }) as TextItem[][] | null;

  if (content.moduleContent.type === 'text') {
    if (!textModeComponentsGroup) {
      // eslint-disable-next-line no-console
      console.error('no text components');
      return null;
    }
    props = {
      theme: content.themeOption && content.themeOption.theme ? content.themeOption.theme : 'Dark',
      type: textModuleContent,
      components: textModeComponentsGroup,
      headingOption: content.headingOption,
      textAlignment: content.textAlignment,
      caeIdentifier: content.caeIdentifierOption,
      disclaimers: content?.disclaimersOption,
    };

    return props;
  }

  const numberModeComponentsGroup = groupComponents({
    components: content.moduleContent?.components,
    type: numberModuleContent,
  }) as NumbersItem[][] | null;

  if (!numberModeComponentsGroup) {
    // eslint-disable-next-line no-console
    console.error('no number components');

    return null;
  }

  props = {
    theme: content.themeOption && content.themeOption.theme ? content.themeOption.theme : 'Dark',
    type: numberModuleContent,
    components: numberModeComponentsGroup,
    textAlignment: content.textAlignment,
    headingOption: content.headingOption,
    caeIdentifier: content.caeIdentifierOption,
    disclaimers: content.disclaimersOption,
  };

  // eslint-disable-next-line no-console
  console.log('>>>after', props);

  return props;
};
