/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { useEffect, useState } from 'react';

export interface ConsumptionAndEmissionsProps {
  caeIds?: string[];
  onCaeReady?: () => void;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
}

interface CaeData {
  consumption: string | React.ReactNodeArray;
  emissions: string | React.ReactNodeArray;
  emissionClass: string | React.ReactNodeArray;
  dischargedCO2Class?: string | React.ReactNodeArray;
  dischargedConsumption?: string | React.ReactNodeArray;
}

export const ConsumptionAndEmissions: React.FC<ConsumptionAndEmissionsProps> = ({
  caeIds,
  onCaeReady,
  vueFormatterService,
  localeService,
}) => {
  if (!caeIds || !caeIds.length) return null;

  const [caeData, setCaeData] = useState<CaeData[]>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
        caeIds,
        vueFormatterService,
        localeService,
      );

      const _caeData: CaeData[] = consumptionsAndEmissionsValues
        .map((cae): CaeData | null => {
          // Check if any of the required fields are defined
          if (cae && (cae.formattedConsumption || cae.formattedEmission || cae.formattedCo2Class)) {
            return {
              consumption: cae.formattedConsumption
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedConsumption || ''),
                  )
                : '',
              emissions: cae.formattedEmission
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedEmission || ''),
                  )
                : '',
              emissionClass: cae.formattedCo2Class
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedCo2Class || ''),
                  )
                : '',
              dischargedConsumption: cae.formattedDischargedConsumption
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedDischargedConsumption),
                  )
                : '',
              dischargedCO2Class: cae.formattedDischargedCo2Class
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedDischargedCo2Class),
                  )
                : '',
            };
          }

          return null;
        })

        .filter((cae): cae is CaeData => cae !== null);

      if (_caeData.length) setCaeData(_caeData);
    })();
  }, [caeIds]);

  useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        <div key={`cae${i}`}>
          <Text key={`c${i}`} variant="copy2">
            {x.consumption}; {x.emissions}
            {x.emissionClass ? '; ' : ''}
            {x.emissionClass ? x.emissionClass : ''}
            {x.dischargedConsumption ? '; ' : ''}
            {x.dischargedConsumption ? x.dischargedConsumption : ''}
            {x.dischargedCO2Class ? `; ` : ''}
            {x.dischargedCO2Class ? x.dischargedCO2Class : ''}
          </Text>
        </div>
      ))}
    </>
  );
};

ConsumptionAndEmissions.displayName = 'ConsumptionAndEmissions';
