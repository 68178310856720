var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@audi/audi-ui-react-v2';
export const MARKER_XHR = 'headless';
const LoadingWrapper = styled.div `
  text-align: center;
  div {
    color: var(${({ theme }) => theme.colors.ui.primary});
  }
`;
export const LayerContentHTML = ({ audiMarketContextService, url, withFefa = false, fefaStyleConfig, enableDeepLinking = false }) => {
    const [html, setHtml] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const layerElementRef = useRef(null);
    const htmlContentElementRef = useRef(null);
    const [randomNumber] = useState(Math.floor(Math.random() * (1000 - 1) + 1));
    const layerElementClassName = `one-layer-headless-${randomNumber}`;
    const [fefaFeatureAppUrl, setFefaFeatureAppUrl] = useState(null);
    const [oneFootnoteJsonUrl, setOneFootnoteJsonUrl] = useState(null);
    useEffect(() => {
        if (typeof window !== 'undefined' && window.SETUPS) {
            const appVersionUrl = window.SETUPS.get('nemo.FefaFeatureAppVersion');
            const footnotesUrl = window.SETUPS.get('nemo.fefa.oneFootnoteJsonUrl');
            if (appVersionUrl) {
                setFefaFeatureAppUrl(appVersionUrl);
            }
            if (footnotesUrl) {
                setOneFootnoteJsonUrl(footnotesUrl);
            }
        }
    }, []);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                let url_ = url;
                if (typeof audiMarketContextService !== 'undefined') {
                    try {
                        yield audiMarketContextService.initMarketContext();
                        const cacheKiller = audiMarketContextService.getContextItem('CacheKiller');
                        url_ = rewriteUrl(url_, cacheKiller);
                    }
                    catch (error) {
                        console.warn(error);
                    }
                }
                const response = yield fetch(url_);
                const data = yield response.text();
                setHtml(data);
            }
            catch (error) {
                setFetchError(new Error('Error fetching Market Context Service'));
            }
        }))();
    }, [audiMarketContextService, url]);
    useEffect(() => {
        if (!document ||
            layerElementRef.current === null ||
            htmlContentElementRef.current === null ||
            html === null) {
            return;
        }
        htmlContentElementRef.current.appendChild(document.createRange().createContextualFragment(html));
        const contentRendered = new CustomEvent('content:rendered', {
            detail: { element: layerElementRef.current }
        });
        const oneLayerLoaded = new CustomEvent('ONELAYER_LOADED', {
            detail: { element: layerElementRef.current }
        });
        document.dispatchEvent(contentRendered);
        document.dispatchEvent(oneLayerLoaded);
    }, [html]);
    useEffect(() => {
        const nonNesseceryElement = ['http:', 'https:', '', 'www'];
        const filteredUrl = url
            .split('/')
            .filter((path) => !nonNesseceryElement.includes(path))
            .join('/');
        if (enableDeepLinking) {
            const newParams = {
                oneLayer: filteredUrl
            };
            window.history.pushState(newParams, '', `#one-layer=${filteredUrl}`);
        }
    }, [url]);
    if (fetchError !== null) {
        return React.createElement("p", null, fetchError instanceof Error ? `Error: - ${fetchError === null || fetchError === void 0 ? void 0 : fetchError.message}` : fetchError);
    }
    if (html === null) {
        return (React.createElement(LoadingWrapper, null,
            React.createElement(Loader, { inverted: true, size: "small", monochrome: true, spaceStackStart: "xl", label: "Loading" })));
    }
    return (React.createElement("div", { className: layerElementClassName, ref: layerElementRef },
        React.createElement("div", { ref: htmlContentElementRef }),
        withFefa && oneFootnoteJsonUrl && fefaFeatureAppUrl && (React.createElement(FEFA, { fefaFeatureAppUrl: fefaFeatureAppUrl, fefaStyleConfig: fefaStyleConfig, layerElementClassName: layerElementClassName, oneFootnoteJsonUrl: oneFootnoteJsonUrl, randomNumber: randomNumber }))));
};
const FEFA = ({ fefaFeatureAppUrl, oneFootnoteJsonUrl, randomNumber, layerElementClassName, fefaStyleConfig }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("feature-app", { config: JSON.stringify(Object.assign({ apiUrls: [oneFootnoteJsonUrl], disclaimerManagerScope: `one-layer-headless-${randomNumber}`, layerElementClassName }, fefaStyleConfig)), id: `audi-footnote-engine-headless-one-layer-${randomNumber}`, src: fefaFeatureAppUrl })));
};
export const rewriteUrl = (url, cacheKiller) => {
    try {
        const ext = url.split('#')[0].split('?')[0].split('.').pop();
        return url.split(ext).join(`${cacheKiller}.${MARKER_XHR}.${ext}`);
    }
    catch (error) {
        console.warn(error);
        return url;
    }
};
