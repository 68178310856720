import React, { FC } from 'react';
import { Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement, UeReference } from '@oneaudi/falcon-tools';
import { HeadingOption, NumbersItem } from '../../types';
import { NumbersHeadlineContainer } from './EditorialColumns.style';

import {
  ComponentContainer,
  ComponentsContainer,
  NumberAndHeadline,
  Row,
} from './NumbersMode.style';

export interface NumbersModeProps {
  animationRef: React.MutableRefObject<null>;
  components: NumbersItem[][];
  inView: boolean;
  headingOption?: HeadingOption;
}

export const NumbersMode: FC<NumbersModeProps> = ({
  animationRef,
  components,
  inView,
  headingOption,
}) => {
  return (
    <>
      {headingOption && (
        <NumbersHeadlineContainer data-testid="text-headline-container">
          <Text
            data-testid="numbers-headline"
            variant="order2"
            as={headingOption.headingTag || 'h2'}
          >
            <UeElement type="text" property="headingOption_heading" label="Headline">
              {renderTextWithFootnotesReferencesV2(headingOption.heading)}
            </UeElement>
          </Text>
        </NumbersHeadlineContainer>
      )}

      <ComponentsContainer data-testid="numbers-components-container" ref={animationRef}>
        {components.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Row data-testid="numbers-components-row" key={`row-${i}`}>
            {row.map((x, j) => (
              <ComponentContainer
                key={`ge-${x.number}`}
                data-testid="numbers-component"
                delay={`${j * 0.3}s`}
                inView={inView}
              >
                <UeReference propertyPath={`moduleContent.components.${j}`}>
                  {x.title && (
                    <Text key={`ge-title-${x.number}`} as="strong">
                      <UeElement type="text" property="title" label="Title">
                        {renderTextWithFootnotesReferencesV2(x.title)}
                      </UeElement>
                    </Text>
                  )}
                  <NumberAndHeadline key={`ge-number-and-headline-${x.number}`}>
                    <Text
                      data-testid="numbers-component-number"
                      key={`ge-number-${x.number}`}
                      variant="order1"
                      weight="bold"
                    >
                      <UeElement type="text" property="number" label="Number">
                        {renderTextWithFootnotesReferencesV2(x.number)}
                      </UeElement>
                    </Text>

                    <Text key={`ge-unit-${x.number}`} variant="copy1" spaceInlineStart="xs">
                      <UeElement type="text" property="unit" label="Unit">
                        {renderTextWithFootnotesReferencesV2(x.unit as string)}
                      </UeElement>
                    </Text>
                  </NumberAndHeadline>
                  {x.description && (
                    <Text key={`ge-description-${x.number}`} variant="copy2">
                      <UeElement type="text" property="description" label="Description">
                        {renderTextWithFootnotesReferencesV2(x.description)}
                      </UeElement>
                    </Text>
                  )}
                </UeReference>
              </ComponentContainer>
            ))}
          </Row>
        ))}
      </ComponentsContainer>
    </>
  );
};
