import { useContext } from 'react';
import { Context } from '../Context';
import { getInstanceIndexNo } from '../utils/utils';
import { completeTargetURL } from '../utils/trackingUtils';

export type LinkElement = 'link' | 'cta';
export type ButtonLabels = Array<{ number: number; linkElement: LinkElement; label: string }>;
export interface Tracking {
  sendClickEvent(
    linkLabel: string,
    linkUrl: string,
    eventName: string,
    linkElement: 'text link' | 'button',
    index?: number,
  ): void;
  sendReadyEvent(): void;
  registerImpressionTracking(heading?: string, buttonLabels?: ButtonLabels | null): void;
}

export function useTracking(
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
  ref?: React.MutableRefObject<null>,
): Tracking {
  const { trackingService } = useContext(Context);
  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendClickEvent: (
      linkLabel: string,
      linkUrl: string,
      eventName: 'text link' | 'cta',
      linkElement: 'text link' | 'button',
      index?: number,
    ) => {
      if (trackingService) {
        const targetURL = completeTargetURL(linkUrl);
        const eventAction = trackingService.evaluateLinkType(targetURL);
        if (!eventAction) {
          // do not track click event if link type could not be evaluated
          return;
        }
        const event = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: linkElement,
              label: linkLabel,
              clickID: '',
              pos: '',
              targetURL,
              value: '',
              relatedElements: [
                {
                  elementName: eventName === 'text link' ? 'link' : 'cta',
                  elementValue: `${index}-${linkLabel}`,
                },
              ],
            },
            eventInfo: {
              eventAction,
              eventName: `${componentNameWithSpaces} - click on ${eventName}`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(event);
      }
    },
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: (
      heading?: string,
      buttonLabels?: Array<{ number: number; linkElement: 'link' | 'cta'; label: string }> | null,
    ) => {
      if (trackingService && ref?.current) {
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                value: heading || '',
                pos: getInstanceIndexNo(ref).toString(),
                targetURL: '',
                relatedElements: buttonLabels
                  ? buttonLabels.map((el) => ({
                      elementName: el.linkElement,
                      elementValue: `${el.number}-${el.label}`,
                    }))
                  : [],
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
  };
}
