import { SpacingShorthandList, Theme, injectSpacing } from '@audi/audi-ui-react';
import DesignTokens from '@audi/audi-ui-design-tokens';
import styled, { keyframes } from 'styled-components';
import { isDarkTheme } from '../utils/utils';

export const EditorialColumnsContainer = styled.div<{
  innerSpacing: SpacingShorthandList;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? DesignTokens.COLOR_BASE_BRAND_BLACK : DesignTokens.COLOR_BASE_BRAND_WHITE};
  padding-inline-start: ${DesignTokens.PAGE_MARGIN_S}px;
  padding-inline-end: ${DesignTokens.PAGE_MARGIN_S}px;
  ${injectSpacing('padding', 'innerSpacing')}

  p {
    word-break: break-word;
    hyphens: auto;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xs}px) {
    padding-inline-start: ${DesignTokens.PAGE_MARGIN_S}px;
    padding-inline-end: ${DesignTokens.PAGE_MARGIN_S}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.l}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xl - 1}px) {
    padding-inline-start: ${2 * DesignTokens.PAGE_MARGIN_L}px;
    padding-inline-end: ${2 * DesignTokens.PAGE_MARGIN_L}px;
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    padding-inline-start: ${2 * DesignTokens.PAGE_MARGIN_XL}px;
    padding-inline-end: ${2 * DesignTokens.PAGE_MARGIN_XL}px;
  }
`;

export const NumbersHeadlineContainer = styled.div`
  text-align: center;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-end:  var(${theme.responsive?.spacing.xxl});
    `}
  }
`;

export const animateContainer = keyframes`
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const LegalInfoContainer = styled.div`
  text-align: center;
`;

export const TextHeadlineContainer = styled.div`
  text-align: center;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-block-end:  var(${theme.responsive?.spacing.xxxl});
    `}
  }
`;
