import * as React from 'react';

interface IntersectionObserverProps extends IntersectionObserverInit {
  stopOnceVisible?: boolean;
  delay?: number;
}
export const useInView = (
  elementRef: React.RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    stopOnceVisible = false,
    delay = 0,
  }: IntersectionObserverProps,
): boolean => {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();
  const [inView, setInView] = React.useState(false);
  const stop = stopOnceVisible && entry?.isIntersecting;
  const updateEntry = ([newEntry]: IntersectionObserverEntry[]): void => {
    setEntry(newEntry);
  };
  React.useEffect(() => {
    const node = elementRef?.current;
    const isServer = typeof window === 'undefined';
    if (isServer || stop || !node) return () => null;
    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);
    return () => observer?.disconnect();
  }, [elementRef, threshold, root, rootMargin, stop]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (entry?.isIntersecting) {
        setInView(entry?.isIntersecting);
      }
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [entry]);
  return inView;
};
