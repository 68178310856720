import { EditorialColumnsFACE } from '../../types';

/**
 * Dev/demo content
 */

export const DEMO_CONTENT_TEXT_MODE: EditorialColumnsFACE = {
  themeOption: {
    theme: 'Dark',
  },
  moduleContent: {
    type: 'text',
    components: [
      {
        title: 'Audi Wallbox',
        description:
          '<p>lorem ipsum dolor sit <b>amet</b>, consectetur <a href="audi.de">adipiscing elit, </a>sed do eiusmod tempor{ft_test-footnote} incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio</p><p><br/></p><ul><li>ipsum <a href="audi.de"> dolor sit amet,</a> consectetur <b>adi</b > piscing </li><li>eiusmod tempor incididunt ut labore et</li> <li>eiusmod tempor incididunt ut labore et </li></ul> <p> abore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitatio <br/> </p>',
        cta: {
          variant: 'text',
          url: 'https://pre-www.audi.de/de/brand/de/elektromobilitaet.html',
          label: 'Call to action',
          openInTabOrLayer: 'tab',
        },
      },
      {
        title: 'Audi Wallbox plus',
        description:
          '<p>lorem ipsum dolor sit amet, consectetur adipisci Duis aute irure dolor in reprehenderit in voluptate Duis aute irure dolor</p><ul><li>ipsum <a href="audi.de">dolor sit amet,</a> consectetur <b>adi</b>piscing</li><li>eiusmod tempor incididunt ut labore et</li><li>eiusmod tempor incididunt ut labore et</li<li>eiusmod tempor incididunt ut labore et</li><li>eiusmod tempor incididunt ut labore et</li></ul>',
        cta: {
          variant: 'secondary',
          url: 'https://fa-tabs.cdn.dev.arcade.apps.one.audi/next/demo/layer.html',
          label: 'Call to action (open layer)',
          openInTabOrLayer: 'layer',
        },
      },
      {
        title: 'Audi Wallbox pro',
        description:
          '<p>lorem ipsum dolor sit <strong>amet</strong>, consectetur <a href="audi.de">adipiscing elit, </a>sed do eiusmod tempor<sup><a href="#test-footnote" target="_self">test</a></sup> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio</p><ul><li>ipsum <a href="audi.de">dolor sit amet,</a> consectetur <strong>adi</strong>piscing</li><li>eiusmod tempor incididunt ut labore e</li><li>eiusmod tempor incididunt ut labore et</li><li>ipsum dolor sit amet, consectetur adipiscing</li><li>eiusmod tempor incididunt ut labore et</li><li>eiusmod tempor incididunt ut labore et</li></ul></span>',
      },
      {
        title: 'Audi Wallbox',
        description:
          '5% to 80%, DC fast charging, 270 kWlorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
      },
      {
        title: 'Audi Wallbox plus',
        description:
          '<p>lorem ipsum dolor sit <b>amet</b>, consectetur <a href="audi.de">adipiscing elit, </a>sed do eiusmod tempor{ft_test-footnote} incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio</p><p><br /></p><ul><li>ipsum <a href="audi.de">dolor sit amet,</a> consectetur <b>adi</b>piscing</li><li>eiusmod tempor incididunt ut labore et</li><li>eiusmod tempor incididunt ut labore et</li></ul>',
      },
      {
        title: 'Audi Wallbox pro',
        description:
          'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
        cta: {
          variant: 'secondary',
          url: 'www.audi.com',
          label: 'Call to action',
          openInTabOrLayer: 'tab',
        },
      },
    ],
  },
  headingOption: {
    heading: 'The Audi e-tron GT',
    headingTag: 'h2',
  },
  textAlignment: 'left',
  caeIdentifierOption: ['a6avantetron'],
  disclaimersOption: ['I am a disclaimer.'],
};

export const DEMO_CONTENT_NUMBERS_MODE: EditorialColumnsFACE = {
  themeOption: {
    theme: 'Dark',
  },
  moduleContent: {
    type: 'numbers',
    components: [
      {
        title: '0-60',
        number: '3.9',
        unit: 's',
        description: 'with Boost engaged',
      },
      {
        title: 'Power',
        number: '522',
        unit: 'HP',
        description: 'with Boost engaged',
      },
      {
        title: 'Aerodynamic',
        number: '0.24',
        description: 'drag coefficient',
      },
      {
        title: 'Distance',
        number: '236',
        unit: 'miles',
        description: 'on a single charge',
      },
      {
        title: 'Battery',
        number: '93',
        unit: 'kWh',
        description: 'gross',
      },
      {
        title: 'Charging',
        number: '23',
        unit: 'mins',
        description: '5% to 80%, DC fast charging, 270 kW',
      },
    ],
  },
  textAlignment: 'left',
  headingOption: {
    heading: 'The Audi e-tron GT',
    headingTag: 'h2',
  },
  caeIdentifierOption: ['ALHA9ESX'],
  disclaimersOption: ['I am a disclaimer.'],
};
