/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App, ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import { ContentServiceV1 } from '@oneaudi/content-service';

import { EditorialColumnsProps } from '../types';
import { EditorialColumns } from './components/EditorialColumns';
import { transformCmsDataToProps } from './utils/transformCmsDataToProps';
import { FaceContent, FalconContent, normalizeContent } from './utils/normalize-falcon-data';

interface FeatureAppProps extends AppProps {
  configProvider?: GfaServiceConfigProviderV1;
  contentService: ContentServiceV1;
}

const FeatureApp: React.FC<FeatureAppProps> = ({
  contentService,
  ...inherited
}: FeatureAppProps) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  const [content, setContent] = React.useState<EditorialColumnsProps | null>(
    transformCmsDataToProps(
      normalizeContent(contentService.getContent() as FalconContent | FaceContent),
    ),
  );

  React.useEffect(() => {
    const listener = () => {
      setContent(
        transformCmsDataToProps(
          normalizeContent(contentService.getContent() as FalconContent | FaceContent),
        ),
      );
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService]);

  if (!content) {
    // eslint-disable-next-line no-console
    console.log('no content');
    return null;
  }

  return (
    <App config={appConfig} app={app} {...inherited}>
      <EditorialColumns {...content} />
    </App>
  );
};

export default FeatureApp;
