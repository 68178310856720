import { Theme } from '@audi/audi-ui-react';
import styled, { css } from 'styled-components';
import { ComponentContainer, ComponentsContainer, Row } from './NumbersMode.style';
import { TextComponentContainerProps } from '../../types';

export const TextRow = styled(Row)`
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    &:not(:last-child) {
      ${({ theme }: { theme: Theme }) =>
        `
      margin-block-end: var(${theme.responsive?.spacing.xxxl});
      `}
    }
  }
`;

export const TextComponentsContainer = styled(ComponentsContainer)`
  ${({ theme }: { theme: Theme }) =>
    `
      margin-bottom: var(${theme.responsive?.spacing.xxl});
      `}

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ theme }: { theme: Theme }) =>
      `
    margin-bottom:  var(${theme.responsive?.spacing.xxl});
    `}
  }
`;

export const TextComponentContainer = styled(ComponentContainer)<TextComponentContainerProps>`
  ${({ textAlignment }) =>
    textAlignment === 'center'
      ? css`
          align-items: center;
          text-align: center;
        `
      : css`
          align-items: flex-start;
          text-align: left;
        `};

  @media screen and (min-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.xs}px) and (max-width: ${({ theme }: { theme: Theme }) =>
      theme.breakpoints.m - 1}px) {
    ${({ theme }: { theme: Theme }) =>
      `
      padding-block-start:  var(${theme.responsive?.spacing.l});
      padding-block-end: var(${theme.responsive?.spacing.l});
    `}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    ${({ isLargePadding }) =>
      isLargePadding
        ? css`
            padding-inline-start: ${({ theme }: { theme: Theme }) =>
              `var(${theme.responsive?.spacing.xxl})`};
            padding-inline-end: ${({ theme }: { theme: Theme }) =>
              `var(${theme.responsive?.spacing.xxxl})`};
            padding-block-start: 0px;
            padding-block-end: 0px;
          `
        : css`
            padding-inline-start: ${({ theme }: { theme: Theme }) =>
              `var(${theme.responsive?.spacing.xl})`};
            padding-inline-end: ${({ theme }: { theme: Theme }) =>
              `var(${theme.responsive?.spacing.xl})`};
            padding-block-start: 0px;
            padding-block-end: 0px;
          `}
  }
`;
