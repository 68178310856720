import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Theme, Text } from '@audi/audi-ui-react';

import { convertRichText } from '../utils/richtextUtils';
import { useTracking } from '../hooks/useTracking';

export const RichtextWrapper = styled.span`
  & span > :last-child {
    margin-bottom: 0px;
  }
  & sup > a {
    border-style: none;
  }
`;

export const StyledText = styled.span`
  & p,
  br {
    margin: 0;
    padding: 0;
    ${({ theme }: { theme: Theme }) =>
      `
      margin-block-end: var(${theme.responsive?.spacing.m});
      font-size: var(${theme.responsive?.typography.copy1.fontSize});
      font-family: var(${theme.responsive?.typography.copy1.fontFamily});
    `}
  }
`;

export interface TextProps {
  readonly text: string;
}

export default memo(function RichText({ text }: TextProps) {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const trackingManager = useTracking();

  useEffect(() => {
    const setTrackingToLinkElement = () => {
      if (!textRef) return;
      if (!textRef.current) return;
      if (!textRef.current?.querySelectorAll('a')) return;
      if (typeof window === 'undefined') return;

      const textLinks = textRef.current.querySelectorAll('a');

      textLinks.forEach((el) => {
        const src = el.getAttributeNode('href')?.value as string;
        const label = el.innerText || '';

        const regexStartWithHash = /^#.*$/;

        if (src && !regexStartWithHash.test(src)) {
          // eslint-disable-next-line no-restricted-globals
          el.addEventListener('click', () => {
            // set opening tag by cliking as a default because all link in the richtext can not set it
            el.setAttribute('target', '_blank');

            trackingManager.sendClickEvent(label, src, 'text link', 'text link');
          });
        }
      });
    };

    return setTrackingToLinkElement();
  }, [trackingManager]);

  return (
    <RichtextWrapper>
      <Text as="span">
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
        <StyledText ref={textRef}>{convertRichText(text)}</StyledText>
      </Text>
    </RichtextWrapper>
  );
});
